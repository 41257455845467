import headerLogo from "../assets/images/Logos/header.png";
import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import menuImage from "../assets/images/Logos/menu-image.jpg";
import locationSvg from "../assets/images/location-pin-svgrepo-com.svg";
import contactSvg from "../assets/images/phone-calling-svgrepo-com.svg";
import emailSvg from "../assets/images/email-svgrepo-com.svg";
import callSvg from "../assets/images/header_telephone.svg";
const links = [
  {
    link: "/#root",
    linkText: "Home",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/residences#root",
    linkText: "Residences",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/building#root",
    linkText: "Building",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/availabilities#root",
    linkText: "Availabilities",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/neighborhood#root",
    linkText: "Neighborhood",
    type: "hash",
    extraClass: "",
  },
  {
    link: "/contact#root",
    linkText: "Contact",
    linkImage: false,
    type: "hash",
    extraClass: "solid",
  },
  {
    link: "tel:5162120430",
    linkText: "(516) 212-0430",
    linkImage: false,
    type: "anchor",
    extraClass: "solid",
  },
];
const documentHTML = document.querySelector("HTML");
const Header = () => {
  const [headerToggle, setHeaderToggle] = useState(false);
  const [headerClasses, setHeaderClasses] = useState([]);

  const reload = () => {
    setHeaderClasses(
      headerClasses.filter((className) => className !== "close")
    );
    documentHTML.style.cssText = "overflow-y: scroll;";
  };
  // function disableScrolling(){
  //   var x=window.scrollX;
  //   var y=window.scrollY;
  //   window.onscroll=function(){window.scrollTo(x, y);};
  // }
  // function enableScrolling(){
  //   window.onscroll=function(){};
  // }
  const handleToggle = () => {
    if (headerToggle) {
      setHeaderClasses(
        headerClasses.filter((className) => className !== "close")
      );
      // enableScrolling()
      documentHTML.style.cssText = "overflow-y: scroll;";
    } else {
      setHeaderClasses([...headerClasses, "close"]);
      // disableScrolling()
      documentHTML.style.cssText = "overflow-y: hidden;";
    }
    setHeaderToggle(headerToggle ? false : true);
  };
  // useEffect(() => {
  //   const handleScroll = () => {
  //     const header = document.querySelector('header');
  //     if (window.scrollY >= 100 && !header.classList.contains('scrolled')) {
  //       setHeaderClasses([...headerClasses, 'scrolled']);

  //     } else if (window.scrollY < 100 && header.classList.contains('scrolled')) {
  //       setHeaderClasses(headerClasses.filter(className => className !== 'scrolled'));
  //     }
  //   };

  //   document.addEventListener('scroll', handleScroll);

  //   // Cleanup function to remove the event listener when component unmounts
  //   return () => {
  //     document.removeEventListener('scroll', handleScroll);
  //   };
  // }, [headerClasses]);
  // (headerToggle ? "" : "closed")
  return (
    <header id="header" className={headerClasses.join(" ")}>
      <div className="header-wrap">
        <div className="links" id="header-links">
          <div className="inner">
            <div className={"link-group"}>
              <div className="link-container">
                {links.map((link, childIndex) => {
                  if (link.type === "hash") {
                    return (
                      <HashLink
                        smooth
                        className={"link"}
                        id={link.extraClass}
                        to={link.link}
                        key={childIndex}
                        onClick={reload}
                      >
                        {link.linkImage ? 
                          <img src={link.linkImage} alt="" />
                          : link.linkText}
                      </HashLink>
                    );
                  }
                  if (link.type === "anchor") {
                    return (
                      <a
                        className={"link"}
                        id={link.extraClass}
                        to={link.link}
                        key={childIndex}
                        onClick={reload}
                      >
                        {link.linkImage ? 
                          <img src={link.linkImage} alt="" />
                          : link.linkText}
                      </a>
                    );
                  }

                  return (
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "link active" : "link"
                      }
                      to={link.link}
                      id={link.extraClass}
                      key={childIndex}
                      onClick={reload}
                    >
                      {link.linkImage ? 
                          <img src={link.linkImage} alt="" />
                          : link.linkText}
                      
                    </NavLink>
                  );
                })}
              </div>

            </div>
          </div>
        </div>
        <div className="btn-wrap">
                <a className="email" href="/contact">
                  <img src={emailSvg} alt="" />
                </a>
                <a className="tel" href="tel:5162120430">
                  <img src={callSvg} alt="" />
                </a>
              </div>
        <div className="logo-wrap">
          <Link to="/">
            <img className="logo" src={headerLogo} alt="" />
          </Link>
        </div>
        <div className="button-wrap">
          <div className="menu-button" onClick={() => handleToggle()}>
            <div className="menu-icon">
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
