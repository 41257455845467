import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Tour3d from "./Tour3d";
export default function Table({ data }) {
  //Data

  const filterTypes = [
    {
      type: "all",
      value: "all",
    },
    {
      type: "studio",
      value: "studio",
    },
    {
      type: "1 bed",
      value: "1-bed",
    },
    {
      type: "2 bed",
      value: "2-bed",
    },
    {
      type: "3 bed",
      value: "3-bed",
    },
  ];
  const [checkValue, setCheckValue] = useState("all");

  const [showFloorPlan, setShowFloorPlan] = useState({
    link: "",
    show: false,
  });
  const [show3dTour, setShow3dTour] = useState({
    link: "",
    show: false,
  });
  //Functions
  const checkIfChecked = (event, value) => {
    setCheckValue(event);
  };
  const handleClickOnView = (link) => {
    if (show3dTour.show === false) {
      console.log('workering')
      setShow3dTour({
        link: link,
        show: true,
      });
    }
    else if (show3dTour.show === true) {
      setShow3dTour({
        link: "",
        show: false,
      });
    }
  };
  const handleClickOnFloorPlan = (link) => {
    if (showFloorPlan.show === false) {

      setShowFloorPlan({
        link: link,
        show: true,
      });
    } else if (showFloorPlan.show === true) {
      setShowFloorPlan({
        link: "",
        show: false,
      });
    }
  };

  useEffect(() => {
    const tableGroups = document.querySelectorAll(".table-group");
    for (let i = 0; i < tableGroups.length; i++) {
      const tableGroup = tableGroups[i];
      if (tableGroup.hasChildNodes()) {
        tableGroup.style.cssText = "display: block;";
      } else {
        tableGroup.style.cssText = "display: none;";
      }
    }
  }, [checkValue]);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("type") && searchParams.get("type") !== "") {
      setCheckValue(searchParams.get("type"));
    }
  }, []);

  return (
    <>
      <section className="availabilities" id="table">
        <h2 className="avaiabilities-heading" id="availabilitiesHeading">
          Availabilities
        </h2>
        {data && (
          <div className="filterChoice">
            <div className="container">
              <h1>Residences Types</h1>
              <form>
                {filterTypes.map((options, i) => {
                  return (
                    <label key={i}>
                      <input
                        type="radio"
                        id={options.type}
                        value={options.value}
                        onChange={(e) => checkIfChecked(e.target.value)}
                        checked={options.value === checkValue}
                        content={options.type}
                      />
                      <span>{options.type}</span>
                    </label>
                  );
                })}
              </form>
            </div>
          </div>
        )}
        {data && (
          <div className="table">
            <div className="inner">
              <div className="table-row table-head">
                <div className="residence cell head">Residence</div>
                <div className="bed-bath cell head">Type</div>
                <div className="price cell head">Price</div>
                <div className="three-d-tour cell head">3D Tour</div>
                <div className="tour3d cell head">Floor Plans</div>
              </div>
              {data.map((group, index) => {
                {
                  return (
                    <div className="table-group" key={index}>
                      {group.map((row, rowIndex) => {
                        if (checkValue !== "all" && checkValue !== row.type) {
                          return;
                        }
                        return (
                          <div className="table-row" key={rowIndex}>
                            <div className="residence cell">
                              <span className="mobile">Residence</span>
                              {row.residence}
                            </div>
                            <div className="bed-bath cell">
                              <span className="label mobile">Type</span>
                              {row.bedBath}
                            </div>
                            <div className="price cell">
                              <span className="label mobile">Price</span>$
                              {row.price}
                            </div>
                            <div className="three-d-tour cell">
                              <span className="label mobile">3D Tour</span>
                              <a style={{ cursor: 'pointer' }} onClick={row.tour3dLink ? () => handleClickOnView(row.tour3dLink) : undefined} >{row.tour3dLink ? "View" : "Coming Soon"}</a>
                            </div>
                            <div
                              className={
                                row.floorPlan
                                  ? "view floorplan cell"
                                  : "floorplan cell"
                              }
                              onClick={
                                row.floorPlan
                                  ? () => handleClickOnFloorPlan(row.floorPlan)
                                  : undefined
                              }
                            >
                              <span className="label mobile">Floor Plan</span>
                              {row.floorPlan ? "View" : "Coming Soon"}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        )}
        {showFloorPlan.show && (
          <div className="show3d-modal availabilities-modal">
            <div className="inner">
              <div className="wrapper">
                <svg
                  className="close-show3d-modal"
                  onClick={handleClickOnFloorPlan}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M19 5L4.99998 19M5.00001 5L19 19"
                      stroke="#ffffff"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </g>
                </svg>
                  <img src={showFloorPlan.link} alt="floorplan" />
              </div>
            </div>
          </div>
        )}
        {show3dTour.show === true && <Tour3d link={show3dTour.link} close={handleClickOnView} />}
      </section>
    </>
  );
}
