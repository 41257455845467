//Banner
import BannerImage from "../assets/images/OneFifty_Availabilities/Banner.png"

//Components
import Table from "../components/Table";
import Footer from "../components/Footer";
import Banner from "../components/Hero";
import QuickNav from "../components/QuickNav";
import BLine from '../assets/images/OneFifty_Availabilities/Floorplans/B_Line.png'
import DLine from '../assets/images/OneFifty_Availabilities/Floorplans/D_Line.png'
import GLine from '../assets/images/OneFifty_Availabilities/Floorplans/G_Line.png'
import HLine from '../assets/images/OneFifty_Availabilities/Floorplans/H_Line.png'
import ILine from '../assets/images/OneFifty_Availabilities/Floorplans/I_Line.png'
import JLine from '../assets/images/OneFifty_Availabilities/Floorplans/J_Line.png'
import KLine from '../assets/images/OneFifty_Availabilities/Floorplans/K_Line.png'
//React Imports
import { Helmet } from "react-helmet";

let QuickLinks = {
    backgroundColor: "white",
    prev: {
        title: "Buildings",
        url: "/building"
    },
    next: {
        title: "Neighborhood",
        url: "/neighborhood"
    }
}
let footerData = {
    backgroundColor: "white"
}
let BannerData = {
    backgroundImage: BannerImage,
    heading: "Your Bushwick Apartment <span>is</span> Waiting",
    description: "Use the table below to search for an apartment for rent at One Fifty Brooklyn that perfectly aligns with your lifestyle.",
    color: "white",
}
let TableData = [
    //Studio//
    [
        {
            residence: "1F",
            type: "studio",
            bedBath: "Studio",
            outdoor: "Terrace",
            price: "3,050.00",
            tour3d: "View",
            tour3dLink: "",
            floorPlan: ""
        },
    ],
    //1 Bed//
    [
        {
            residence: "1B",
            type: "1-bed",
            bedBath: "1 Bed | 1 Bath",
            outdoor: "Terrace",
            price: "3,750.00",
            tour3d: "View",
            tour3dLink: "https://my.matterport.com/show/?m=CYG7hDgokDH",
            floorPlan : BLine,
        },
        {
            residence: "2K",
            type: "1-bed",
            bedBath: "1 Bed | 1 Bath",
            outdoor: "Balcony",
            price: "3,750.00",
            tour3d: "View",
            tour3dLink: "https://my.matterport.com/show/?m=7uPuAjpMQFQ",
            floorPlan: KLine,
        },
        {
            residence: "3D",
            type: "1-bed",
            bedBath: "1 Bed | 1 Bath",
            outdoor: "Balcony",
            price: "3,600.00",
            tour3d: "View",
            tour3dLink: "https://my.matterport.com/show/?m=FnmSHbFBJ6U",
            floorPlan: DLine,
        },
    ],
    //2 Bed//
    [
        {
            residence: "2J",
            type: "2-bed",
            bedBath: "2 Bed | 2 Bath",
            outdoor: "",
            price: "4,850.00",
            tour3d: "View",
            tour3dLink: "https://my.matterport.com/show/?m=GdvRv9zcTeS",
            floorPlan: JLine,
        },
        {
            residence: "3G",
            type: "2-bed",
            bedBath: "2 Bed | 2 Bath",
            outdoor: "",
            price: "4,900.00",
            tour3d: "View",
            tour3dLink: "https://my.matterport.com/show/?m=ZvnhPiaPo21",
            floorPlan: GLine,
        },
        {
            residence: "4G",
            type: "2-bed",
            bedBath: "2 Bed | 2 Bath",
            outdoor: "",
            price: "5,000.00",
            tour3d: "View",
            tour3dLink: "https://my.matterport.com/show/?m=ZvnhPiaPo21",
            floorPlan: GLine
        },
    ],
    //3 Bed//
    [
        {
            residence: "3I",
            type: "3-bed",
            bedBath: "3 Bed | 2 Bath",
            outdoor: "",
            price: "6,700.00",
            tour3d: "View",
            tour3dLink: "https://my.matterport.com/show/?m=HwGgsmHq7TL",
            floorPlan: ILine,
        },
        {
            residence: "4H",
            type: "3-bed",
            bedBath: "3 Bed | 2 Bath",
            outdoor: "",
            price: "6,800.00",
            tour3d: "View",
            tour3dLink: "https://my.matterport.com/show/?m=KAFmVxy1av4",
            floorPlan: HLine,
        },
    ],
]

export default function Availabilities() {
    return (
        <>
            <Helmet>
                <title>Availability| Bushwick Apartments For Rent at One Fifty</title>
                <meta name="description" content="Explore our available units to find the luxury Bushwick apartment at One Fifty that perfectly suits your lifestyle." />
            </Helmet>
            <Banner data={BannerData} />
            <Table data={TableData} />
            <QuickNav links={QuickLinks} />
            <Footer data={footerData} />
        </>
    )
}