import { HashLink } from "react-router-hash-link";
export default function QuickNav({links}) {
  const { prev, next , parrallax } = links;
  
  const scrollUp = () => {
    window.scrollTo(0, 0);
  };
  return (
    <section className={"quick-navigation " + (parrallax ? "parrallax" : "")} id={links.backgroundColor}>
      <div className="inner">
        <HashLink className="prev-page" to={`${prev.url}#root`} smooth>
          <svg
            className="arrow leftarrow"
            width="64px"
            height="64px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#fff"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
              <path
                d="M13.26 15.53L9.73999 12L13.26 8.46997"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
            </g>
          </svg>
          <p>{prev.title}</p>
        </HashLink>
        <HashLink className="heading-wrap" to="/contact#form" smooth>
          <h2>Schedule a Tour</h2>
        </HashLink>
        <HashLink className="next-page" to={`${next.url}#root`}>
          <p>{next.title}</p>
          <svg
            className="arrow rightarrow"
            width="64px"
            height="64px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              {" "}
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke="#fff"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
              <path
                d="M10.74 15.53L14.26 12L10.74 8.46997"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>{" "}
            </g>
          </svg>
        </HashLink>
      </div>
    </section>
  );
}
