export default function Copyright({ showModal, ...other }) {
    const date = new Date().getFullYear();
    return (
        <div {...other}>
            <div className="extra-links">
                <p className="accessibility" onClick={showModal}>
                    Accessibility
                </p>{" "}
                |
                <p className="fair-housing" onClick={showModal}>
                    Fair Housing Notice
                </p>
            </div>
            <p> Copyright © {date} EXR, All rights reserved.</p>
        </div>
    )
}