//Components
import TwoColumn from "../components/TwoColumn";
import Footer from "../components/Footer";
import QuickNav from "../components/QuickNav";
import Banner from "../components/Hero";

//Banner
import BannerImage from "../assets/images/OneFifty_Building_v2/Buildings-Hero-Replacement.png"

//Fitness Centre
import sec1Image from "../assets/images/OneFifty_Building_v2/One_Fifty_Ammenities_FitnessCentre.jpg";

//Mailroom
import sec2Image1 from "../assets/images/OneFifty_Building_v2/section_2_image_1_separate.png";
import sec2Image2 from "../assets/images/OneFifty_Building_v2/section_2_image_2_separate.png";

//Lounge with Fireplace
import sec3Image1 from "../assets/images/OneFifty_Building_v2/section_3_image_1.jpg";
import sec3Image2 from "../assets/images/OneFifty_Building_v2/section_3_image_2.jpg";
import sec3Image3 from "../assets/images/OneFifty_Building_v2/section_3_image_3.jpg";

//Courtyard
import sec4Image1 from "../assets/images/OneFifty_Building_v2/section_4_image_1.png";
import sec4Image2 from "../assets/images/OneFifty_Building_v2/150_Court_yard-2.jpg";
import sec4Image3 from "../assets/images/OneFifty_Building_v2/section_4_image_3_separate.png";

//Rooftop Screening Area
import sec5Image1 from "../assets/images/OneFifty_Building_v2/section_5_image_3.png";
import sec5Image2 from "../assets/images/OneFifty_Building_v2/section_5_image_1_separate.png";
import sec5Image3 from "../assets/images/OneFifty_Building_v2/section_5_image_2_separate.png";

//Business Centre
import sec6Image1 from "../assets/images/OneFifty_Building_v2/section_6.png";
import sec6Image2 from "../assets/images/OneFifty_Building_v2/One_Fifty_Ammenities_BuisnessCentre-2.jpg";

//Billiard Room
import sec7Image1 from "../assets/images/OneFifty_Building_v2/section_7_image_1.png";
import sec7Image2 from "../assets/images/OneFifty_Building_v2/One_Fifty_Ammenities_BiliardRoom-2.jpg";

//Yoga Studio
import sec8Image from "../assets/images/OneFifty_Building_v2/section_7_image_2.png";

//Laundry Room
import sec9Image from "../assets/images/OneFifty_Building_v2/section_8.jpg";

//Pet-spa
import sec10Image from "../assets/images/OneFifty_Building_v2/section_9.jpg";

//Bike Room
import sec11Image from "../assets/images/OneFifty_Building_v2/section_11.jpg";

//Bike Room
import sec12Image from "../assets/images/OneFifty_Building_v2/section_12.jpg";

//React Imports
import { Helmet } from "react-helmet";



let QuickLinks = {
    backgroundColor: "green",
    prev: {
        title: "Residences",
        url: "/residences"
    },
    next: {
        title: "Availabilities",
        url: "/availabilities"
    }
}
let footerData = {
    backgroundColor: "green"
}
let BannerData = {
    backgroundImage: BannerImage,
    backgroundColor: "normal",
    heading: "Change The Way You Live",
    description: "The amenities at One Fifty have been carefully designed to make your life better. A fitness center provides state-of-the-art equipment and a boutique-style yoga studio just steps from your front door. Curl up in front of the fireplace in the lounge or enjoy a movie under the stars on the rooftop’s outdoor screening area. This building offers more than just a place to rest your head – it’s filled with amenities designed to make your life more convenient, more efficient, and way more fun.",
    color : "white",
}

const twoCoulmnData = [
    {
        variant: "white-brown",
        heading: "Courtyard",
        description: "Step outside for a breath of fresh air in the Courtyard – an open-air gathering space with separate seating areas.",
        images: [sec4Image1, sec4Image2, sec4Image3],
        flexDirection: "row",
        hasPricing : false,
        padding: "20px 40px 30px",
        price: "",
    },
    {
        variant: "brown",
        heading: "Rooftop Screening Area",
        description: "On warm summer nights, nothing beats an outdoor movie under the stars at the building’s Rooftop Screening Area",
        images: [sec5Image1, sec5Image2, sec5Image3],
        flexDirection: "row-reverse",
        hasPricing : false,
        padding: "20px 40px 30px",
    },
    {
        variant: "white-orange",
        heading: "Business Centre",
        description: "Separate where you work from where you live by taking advantage of the on-site Business Center, which features high-speed internet access, comfortable seating areas, and a professional atmosphere.",
        images: [sec6Image1, sec6Image2],
        flexDirection: "row",
        hasPricing : false,
        padding: "40px 40px 60px",
    },
    {
        variant: "brown",
        heading: "Pet Spa",
        description: "Keep your pets clean and happy with our convenient pet spa. Our facility offers grooming stations, pet baths, and essential care services to ensure your furry friends are well taken care of.",
        images: [sec10Image],
        flexDirection: "row-reverse",
        padding: "40px",
        hasPricing : false,
    },
    {
        variant: "green",
        heading: "Cozy Lounge",
        description: "The building’s communal lounge is great for gathering with a group or simply stepping away from your main living space. Designed with comfort and style in mind, it's the perfect spot to unwind, socialize, or enjoy a quiet moment",
        images: [sec3Image1, sec3Image2, sec3Image3],
        flexDirection: "row",
        padding: "40px",
        hasPricing : false,
    },
    {
        variant: "white-brown",
        heading: "Billiard Room",
        description: "Perfect for gathering with friends or entertaining guests, the on-site Billiard Room is ready to serve as a stylish extension of your living space.",
        images: [sec7Image1, sec7Image2],
        flexDirection: "row-reverse",
        hasPricing : false,
    },
    {
        variant: "brown",
        heading: "Fitness Center",
        description: "Achieve your fitness goals around your own schedule. The on-site Fitness Center offers brand new cardio machines and fitness equipment just steps from your front door.",
        images: [sec1Image],
        flexDirection: "row",
        hasPricing : false,
    },
    {
        variant: "white-orange",
        heading: "Yoga Studio",
        description: "A boutique-style Yoga Studio allows you to find your center and create a well-rounded fitness routine without ever leaving home.",
        images: [sec8Image],
        flexDirection: "row-reverse",
        hasPricing : false,
    },


    {
        variant: "brown",
        heading: "Parking Garage",
        description: "Say goodbye to circling the block in search of a parking spot. Our secure parking garage offers the convenience of designated spaces, ensuring your vehicle is always available when you need it. Enjoy the ease and peace of mind that comes with having your own reserved parking spot.",
        images: [sec12Image],
        flexDirection: "row",
        hasPricing : false,
    },
    {
        variant: "green",
        heading: "Mailroom",
        description: "All your packages and deliveries will be waiting for you in the building’s secure mailroom.",
        images: [sec2Image1, sec2Image2],
        flexDirection: "row-reverse",
        hasPricing : false,
    },
    {
        variant: "white-brown",
        heading: "Laundry Room",
        description: "Every floor in One Fifty features its own personal Laundry Room, providing you with convenient access to washers and dryers whenever you need them. Additionally, our Laundry Room app notifies you of machine availability and alerts you when your laundry is ready.",
        images: [sec9Image],
        flexDirection: "row-reverse",
        hasPricing : false,
    },
    {
        variant: "green",
        heading: "Bike Room",
        description: "Secure and store your bicycle with ease in our dedicated bike room. Equipped with ample racks and secure locking mechanisms, our facility ensures your bike is safe and accessible whenever you need it.",
        images: [sec11Image],
        flexDirection: "row ",
        hasPricing : false,
    },
]

export default function Building() {
    return (
        <>
            <Helmet>
                <title>Upscale Amenities | Brooklyn’s One Fifty Apartment Building</title>
                <meta name="description" content="Along with luxury Bushwick apartments, One Fifty offers upscale amenities like a fitness center, lounge, and rooftop screening area." />
            </Helmet>
            <Banner data={BannerData} />
            {
                twoCoulmnData.map((section, index) => {
                    return <TwoColumn key={index} data={section} />;
                })
            }
            <QuickNav links={QuickLinks} />
            <Footer data={footerData} />
        </>
    )
}